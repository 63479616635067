// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: true,
  GA_TRACKER_ID: 'UA-140814636-4',
};

export const GOOGLE_MAPS_API_KEY = 'AIzaSyDgHymau46x7i4tmyjG6SYssFw63RC905I';
export const API_URL = 'https://api.test.statoutdoors.com';
export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51J7tiqGWJyYGy2XwXg9tO5yiti2KaPDuJ9nZuAIsS1FGkdFTcRW12jc7VBaTDeVxAE0XOyulBRWv46Lb43j7OtFV00Z5paCTeN'

export const BUGSNAG_API_KEY = 'af9a98d79efb57163e64386ea3641864'
export const GLASSFY_API_KEY = '605ea6cf33dd44eb8ea51c3e0822a312'


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
